<template><v-footer dark padless>
    <v-row><v-col cols="12">
    <v-card flat tile class="indigo lighten-1 white--text text-center">
        <v-card-text>
            <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
            <v-icon size="24px">{{ icon }}</v-icon>
        </v-btn>
        </v-card-text>

      <v-card-text class="white--text pt-0">
        Terima kasih telah menggunakan web ini
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Perpustakaan Darul Fikri Manela</strong>
      </v-card-text>
    </v-card>
    </v-col></v-row>
</v-footer></template>
<script>
    export default {
        data () {
            return {
                icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram',]
            }
        }
    }
</script>
