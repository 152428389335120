<template><div>
<v-system-bar app clipped-left dark>
    <v-icon>fa-phone</v-icon><span class="mr-2">(0322) 321649</span>
    <v-icon>fa-envelope</v-icon><span>support@man1lamongan.sch.id</span>
    <v-spacer/>
    <v-icon>mdi-facebook</v-icon>
    <v-icon>mdi-instagram</v-icon>
</v-system-bar>


<v-app-bar class="cyan" app clipped-left>
    <div class="d-md-none">
        <v-app-bar-title><v-app-bar-nav-icon @click.stop="drawer = !drawer" /> Sekolah</v-app-bar-title>
    </div>
    <div class="d-none d-md-flex">
    <a style="text-decoration:none;" href="/"><v-btn class="mr-4 blue" dark><v-icon left>fa-home</v-icon>{{ $t('Home') }}</v-btn></a>
    <a style="text-decoration:none;" href="/katalog-list"><v-btn class="mr-4 blue" dark><v-icon left>fa-book</v-icon>{{ $t('Catalogue') }}</v-btn></a>
    <a style="text-decoration:none;" href="/pinjaman"><v-btn class="mr-4 blue" dark><v-icon left>fa-hand-paper</v-icon>{{ $t('Borrowing') }}</v-btn></a>
    <a style="text-decoration:none;" href="/history"><v-btn class="mr-4 blue" dark><v-icon left>fa-history</v-icon>{{ $t('History') }}</v-btn></a>
    <a style="text-decoration:none;" href="/profile"><v-btn class="mr-4 blue" dark><v-icon left>fa-user</v-icon>{{ $t('Profile') }}</v-btn></a>
    </div>
    <v-spacer/>
    <v-btn v-show="showRight" @click="$store.state.showSearchForm = !$store.state.showSearchForm;" class="mx-2" small fab dark color="green"><v-icon dark>mdi-magnify</v-icon></v-btn>
    <a style="text-decoration:none;" href="/logout"><v-btn class="d-none d-md-flex" dark color="red"><v-icon dark class="mr-3">fa-sign-out-alt</v-icon> Log out</v-btn></a>
</v-app-bar>

<v-navigation-drawer v-model="drawer" class="d-md-none" clipped app color="blue accent-1">
    <v-list-item>
    <v-list-item-content>
        <v-list-item-title>{{ $t('Menu') }}</v-list-item-title>
    </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
        <a style="text-decoration:none;" href="/"><v-list-item link><v-list-item-icon><v-icon>fa-home</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>{{ $t('Home') }}</v-list-item-title></v-list-item-content></v-list-item></a>
        <a style="text-decoration:none;" href="/katalog-list"><v-list-item link><v-list-item-icon><v-icon>fa-book</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>{{ $t('Catalogue') }}</v-list-item-title></v-list-item-content></v-list-item></a>        
        <a style="text-decoration:none;" href="/pinjaman"><v-list-item link><v-list-item-icon><v-icon>fa-hand-paper</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>{{ $t('Borrowing') }}</v-list-item-title></v-list-item-content></v-list-item></a>
        <a style="text-decoration:none;" href="/history"><v-list-item link><v-list-item-icon><v-icon>fa-history</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>{{ $t('History') }}</v-list-item-title></v-list-item-content></v-list-item></a>
        <a style="text-decoration:none;" href="/profile"><v-list-item link><v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>{{ $t('Profile') }}</v-list-item-title></v-list-item-content></v-list-item></a>
    </v-list>
    
    <template v-slot:append><a style="text-decoration:none;" href="/logout">
        <div class="pa-2">
          <v-btn block>
            Logout
          </v-btn>
        </div>
      </a></template>
</v-navigation-drawer>

<v-bottom-navigation fixed app class="d-md-none" background-color="info">    
    <v-btn @click="linkto('/')"><span>{{ $t('Home') }}</span><v-icon>fa-home</v-icon></v-btn>
    <v-btn @click="linkto('/katalog-list')"><span>{{ $t('Catalogue') }}</span><v-icon>fa-book</v-icon></v-btn>
    <v-btn @click="linkto('/pinjaman')"><span>{{ $t('Borrowing') }}</span><v-icon>fa-hand-paper</v-icon></v-btn>
    <v-btn @click="linkto('/history')"><span>{{ $t('History') }}</span><v-icon>fa-history</v-icon></v-btn>
    <v-btn @click="linkto('/profile')"><span>{{ $t('Profile') }}</span><v-icon>fa-user</v-icon></v-btn>
</v-bottom-navigation>

</div></template>
<script>
    export default {
        data () {
            return {
                drawer: false,
                showRight: false
            }
        },
        methods:{
            linkto(str) {
                window.location.href = str;
            }
        },
        mounted() {
            var routeName = this.$route.name;
            if ('KatalogList' == routeName) {
                this.showRight = true;
            }
        }
    }
</script>
